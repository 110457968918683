// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed } from 'vue'
import { store } from '@/store'

import { getSessionUrl, themeColors } from '@/common/shared.js'

export const sessionUrl = (ownerName, sessionName, sessionId, isUniqueSessionName) => {
  const uniqueSessionName = (isUniqueSessionName && sessionName) || sessionId
  return getSessionUrl(ownerName, uniqueSessionName)
}

export const tableInputStyle = computed(() => {
  return {
    backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
    color: themeColors[store.getters.currentThemeName].modalTextColor,
    border: 'none',
    borderBottom: store.getters.isLightMode ? '1px solid black' : '1px solid white',
    '--errorOutlineColor': themeColors[store.getters.currentThemeName].errorBackgroundColor,
  }
})

export const tableStyle = computed(() => {
  return {
    backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
    color: themeColors[store.getters.currentThemeName].modalTextColor,
    border: `1px solid ${themeColors[store.getters.currentThemeName].toolBorderColor}`,
  }
})
