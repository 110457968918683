<template>
  <create-session-modal :open="newSessionModalOpen" @close="onCloseNewSessionModal">
  </create-session-modal>
  <confirmation-modal dialogClass="delete-session-modal" :open="deleteSessionModalOpen"
    @close="onCloseDeleteSessionModal" :confirm-func="deleteSessionConfirmFunc">
    <template v-slot:title>
      {{ tr('Delete Session') }}
    </template>

    <template v-slot:description>
      {{ tr('Deleting this session is permanent and cannot be undone.') }}
    </template>

    <template v-slot:cancel>
      {{ tr('Cancel') }}
    </template>

    <template v-slot:confirm>
      {{ tr('Delete') }}
    </template>
  </confirmation-modal>
  <collaborators-modal v-if="featureToggles.enableSessionSharing" :open="collaboratorsModalOpen"
    @close="onCloseCollaboratorsModal" :currentUserIds="currentUserIds" :onUpdateUserIds="onUpdateUserIds"
    :title="collaboratorsModalTitle">
  </collaborators-modal>
  <the-header>
  </the-header>
  <section class="section">
    <div class="card" :style="tableStyle">
      <h1>{{ tr('Control Dashboard') }}</h1>
      <h2>
        <div @click.prevent="onOpenNewSessionModal" class="create-new-session-button" :style="tableStyle" role="button"
          tabindex="0" @keyup.enter="onOpenNewSessionModal">
          {{ tr('Create new session') }}
        </div>
        <div @click.prevent="onReturnToSession" class="return-to-session-button" :style="tableStyle" role="button"
          tabindex="0" @keyup.enter="onReturnToSession">
          {{ tr('Return') }}
        </div>
      </h2>
      <h2>{{ tr('Sessions owned by me') }} </h2>
      <div id="table-owned">
        <div class="mdc-data-table__table-container">
          <table role="ownedTable" class="mdc-data-table__table" :aria-label="tr('Sessions owned by me')"
            style="white-space: normal;">
            <owned-table-header>
            </owned-table-header>
            <tbody class="mdc-data-table__content">
              <owned-table-row v-for="session in sessionsOwnedByMe" :key="session.id" :sessionId="session.id"
                :formatLastAccessed="formatLastAccessed" :onDuplicateSession="onDuplicateSession"
                :onOpenCollaboratorsModal="onOpenCollaboratorsModal"
                :onOpenDeleteSessionModal="onOpenDeleteSessionModal" :onOpenViewersModal="onOpenViewersModal"
                :setDeleteSessionConfirmFunc="setDeleteSessionConfirmFunc">
              </owned-table-row>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="featureToggles.enableSessionSharing">
        <h2>{{ tr('Sessions shared with me') }} </h2>
        <div id="table-shared">
          <div class="mdc-data-table__table-container">
            <table role="sharedTable" class="mdc-data-table__table" :aria-label="tr('Sessions shared with me')"
              style="white-space: normal;">
              <shared-table-header>
              </shared-table-header>
              <tbody class="mdc-data-table__content">
                <shared-table-row v-for="session in sessionsSharedWithMe" :key="session.id" :sessionId="session.id"
                  :formatLastAccessed="formatLastAccessed" :onDuplicateSession="onDuplicateSession"
                  :sharingStatus="sharingStatus(session.id)">
                </shared-table-row>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

import CollaboratorsModal from '@/components/ui/CollaboratorsModal/CollaboratorsModal.vue'
import ConfirmationModal from '@/components/ui/ConfirmationModal/ConfirmationModal.vue'
import CreateSessionModal from '@/components/ui/CreateSessionModal/CreateSessionModal.vue'
import { sanitizeUrlSegment, shortenId, themeColors } from '@/common/shared.js'
import router from '@/router'

import OwnedTableHeader from './OwnedTableHeader.vue'
import OwnedTableRow from './OwnedTableRow.vue'
import SharedTableHeader from './SharedTableHeader.vue'
import SharedTableRow from './SharedTableRow.vue'
import { tableStyle } from './tables.js'

export default {
  components: {
    CollaboratorsModal,
    ConfirmationModal,
    CreateSessionModal,
    OwnedTableHeader,
    OwnedTableRow,
    SharedTableHeader,
    SharedTableRow,
  },
  setup() {
    const store = useStore()

    const tr = inject('tr')

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].modalIconColor
    })

    const sessionsOwnedByMe = computed(() => store.getters.sessionsOwnedByMe)
    const sessionsSharedWithMe = computed(() => store.getters.sessionsSharedWithMe)

    const formatLastAccessed = computed(() => {
      return (timestampMillis) => {
        if (timestampMillis === 0) { return tr.value('never') }
        const date = new Date(timestampMillis)
        return store.getters.formatDateDistance(date, currentTimeDate.value)
      }
    })

    const currentTimeDate = ref(new Date())

    const onDuplicateSession = (srcSession) => {
      const id = uuidv4()
      const lastAccessed = new Date().getTime()
      const sessionName = srcSession.name ? `${srcSession.name}-${id.slice(0, 4)}` : shortenId(srcSession.id)
      const session = {
        id,
        name: sessionName.includes('copy of') ? sessionName : `copy of ${sessionName}`,
        ownerId: store.getters.userId,
        ownerName: store.getters.profile.displayName,
        locallyAnonymized: !!srcSession.locallyAnonymized,
        patient: srcSession.patient || {},
        lastAccessed,
        collaborateWithAnyoneLink: !!srcSession.collaborateWithAnyoneLink,
        collaborators: [],
        viewerWithAnyoneLink: !!srcSession.viewerWithAnyoneLink,
        viewers: [],
        toolCount: srcSession.toolCount,
      }
      store.dispatch('createNewSession', { session, publish: true })
      store.dispatch('duplicateSessionTools', {
        srcSessionOwnerId: srcSession.ownerId,
        srcSessionId: srcSession.id,
        dstSession: session,
      })
      // Redirect to the new session.
      const url = `/user/${sanitizeUrlSegment(session.ownerName)}/session/${session.id}`
      console.log(url)
      store.dispatch('routeToUrl', url)
    }

    const currentUserIds = ref([])
    const addAndRemoveUsers = (session, after, hasAccess) => {
      const before = new Set(currentUserIds.value)
      before.delete('all-users-with-link')
      const removedIds = [...before].filter(x => !after.has(x))
      const addedIds = [...after].filter(x => !before.has(x))
      removedIds.forEach((id) => {
        if (hasAccess.has(id)) { return }
        store.dispatch('removeSessionSharing', {
          ownerId: session.ownerId,
          sessionId: session.id,
          userId: id,
          publish: true,
        })
      })
      addedIds.forEach((id) => {
        // TODO: Why can't this session be sent without copying?
        const newSession = {
          id: session.id,
          name: session.name,
          ownerId: session.ownerId,
          ownerName: session.ownerName,
          locallyAnonymized: session.locallyAnonymized,
          patient: session.patient,
          lastAccessed: 0,  // 'never'
          collaborateWithAnyoneLink: session.collaborateWithAnyoneLink,
          collaborators: session.collaborators,
          viewerWithAnyoneLink: session.viewerWithAnyoneLink,
          viewers: session.viewers,
          toolCount: session.toolCount,
        }
        store.dispatch('addSessionSharing', {
          session: newSession,
          userId: id,
          publish: true,
        })
      })
    }

    const onUpdateUserIds = ref(() => { })
    const onUpdateCollaborators = (session, userIds) => {
      const collaborateWithAnyoneLink = userIds.some((id) => id === 'all-users-with-link')
      const collaborators = userIds.filter((id) => id !== 'all-users-with-link')
      const msg = {
        ownerId: session.ownerId,
        sessionId: session.id,
        collaborateWithAnyoneLink,
        collaborators,
        viewerWithAnyoneLink: session.viewerWithAnyoneLink,
        viewers: session.viewers,
        publish: true,
      }
      store.dispatch('setSessionSharing', msg)
      const hasAccess = new Set(session.viewers)
      addAndRemoveUsers(session, new Set(collaborators), hasAccess)
    }

    const onUpdateViewers = (session, userIds) => {
      const viewerWithAnyoneLink = userIds.some((id) => id === 'all-users-with-link')
      const viewers = userIds.filter((id) => id !== 'all-users-with-link')
      const msg = {
        ownerId: session.ownerId,
        sessionId: session.id,
        collaborateWithAnyoneLink: session.collaborateWithAnyoneLink,
        collaborators: session.collaborators,
        viewerWithAnyoneLink,
        viewers,
        publish: true,
      }
      store.dispatch('setSessionSharing', msg)
      const hasAccess = new Set(session.collaborators)
      addAndRemoveUsers(session, new Set(viewers), hasAccess)
    }

    const featureToggles = computed(() => store.getters.featureToggles)
    const tutorialNext = inject('tutorialNext')

    const newSessionModalOpen = ref(false)
    const onOpenNewSessionModal = () => {
      newSessionModalOpen.value = true
      setTimeout(tutorialNext, 300)  // Required to position properly.
    }
    const onCloseNewSessionModal = () => {
      newSessionModalOpen.value = false
      setTimeout(() => { currentTimeDate.value = new Date() }, 50)
    }

    const collaboratorsModalOpen = ref(false)
    const collaboratorsModalTitle = ref('')
    const onOpenCollaboratorsModal = (sessionId) => {
      const session = store.getters.sessionById(sessionId)
      currentUserIds.value = [
        ...session.collaborators,
        ...(session.collaborateWithAnyoneLink ? ['all-users-with-link'] : []),
      ]
      onUpdateUserIds.value = (userIds) => onUpdateCollaborators(session, userIds)
      collaboratorsModalTitle.value = 'Add or remove collaborators'
      collaboratorsModalOpen.value = true
    }
    const onCloseCollaboratorsModal = () => {
      collaboratorsModalOpen.value = false
      setTimeout(() => { currentTimeDate.value = new Date() }, 50)
    }
    const onOpenViewersModal = (sessionId) => {
      const session = store.getters.sessionById(sessionId)
      currentUserIds.value = [
        ...session.viewers,
        ...(session.viewerWithAnyoneLink ? ['all-users-with-link'] : []),
      ]
      onUpdateUserIds.value = (userIds) => onUpdateViewers(session, userIds)
      collaboratorsModalTitle.value = 'Add or remove viewers'
      collaboratorsModalOpen.value = true
    }
    const onCloseViewersModal = () => {
      collaboratorsModalOpen.value = false
      setTimeout(() => { currentTimeDate.value = new Date() }, 50)
    }

    const deleteSessionConfirmFunc = ref(() => { })
    const deleteSessionModalOpen = ref(false)
    const onOpenDeleteSessionModal = () => {
      deleteSessionModalOpen.value = true
    }
    const onCloseDeleteSessionModal = () => {
      deleteSessionModalOpen.value = false
    }
    const setDeleteSessionConfirmFunc = (f) => deleteSessionConfirmFunc.value = f

    const routeToMostRecentSession = computed(() => store.getters.mostRecentSessionUrlOrDashboard)
    const onReturnToSession = () => {
      router.push(routeToMostRecentSession.value)
    }

    const sharingStatus = (id) => {
      const session = store.getters.sessionById(id)
      if (!session) { return 'viewer' }
      const userId = store.getters.userId
      const collaboratorsIds = session.collaborators
      if (collaboratorsIds.includes(userId)) { return 'collaborator' }
      return 'viewer'
    }

    let pageTimeKeeper = null
    onMounted(() => {
      pageTimeKeeper = setInterval(() => {
        currentTimeDate.value = new Date()
      }, 60 * 1000) // once per minute

      if (store.getters.allUsers.length === 0) {
        store.dispatch('getAllUsers')
      }
    })

    onBeforeUnmount(() => {
      clearInterval(pageTimeKeeper)
    })

    return {
      collaboratorsModalOpen,
      collaboratorsModalTitle,
      currentUserIds,
      deleteSessionConfirmFunc,
      deleteSessionModalOpen,
      featureToggles,
      formatLastAccessed,
      modalIconColor,
      newSessionModalOpen,
      onCloseCollaboratorsModal,
      onCloseDeleteSessionModal,
      onCloseNewSessionModal,
      onCloseViewersModal,
      onDuplicateSession,
      onOpenCollaboratorsModal,
      onOpenDeleteSessionModal,
      onOpenNewSessionModal,
      onOpenViewersModal,
      onReturnToSession,
      onUpdateCollaborators,
      onUpdateUserIds,
      onUpdateViewers,
      sessionsOwnedByMe,
      sessionsSharedWithMe,
      setDeleteSessionConfirmFunc,
      sharingStatus,
      shortenId,
      tableStyle,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './tables.scss';

.return-to-session-button {
  display: inline-block;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  padding: 8px;
  background-color: #ffffff;
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    background-color: #ddd;
    transform: scale(1.1);
  }
}
</style>
