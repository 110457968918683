<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 576"
    :fill="modalIconColor"
  >
    <title>{{ title }}</title>
    <path d="M288 176a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 176zm284.52 97.4C518.29 167.59 410.93 96 288 96 165.07 96 57.68 167.64 3.48 273.41a32.35 32.35 0 0 0 0 29.19C57.71 408.41 165.07 480 288 480c122.93 0 230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 432c-98.65 0-189.09-55-237.93-144C98.91 199 189.34 144 288 144s189.09 55 237.93 144C477.1 377 386.66 432 288 432z" />
  </svg>
</template>


<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].modalIconColor
    })

    return {
      modalIconColor,
    }
  },
}
</script>
