<template>
  <thead>
    <tr class="mdc-data-table__header-row">
      <th class="mdc-data-table__header-cell mdc-data-table__header-cell--checkbox" role="columnheader" scope="col"
        v-if="featureToggles.dashboardMultiSessionSelect" style="width: 3rem;" :style="tableStyle">
      </th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" :style="[tableStyle, fontStyle]">{{
          tr('Session ID')
      }}</th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" :style="[tableStyle, fontStyle]">{{
          tr('Session Name (optional)')
      }}<span class="dashboard-tip" tabindex="0"
          @focus="showTipParagraph('session-name-tip-paragraph')"
          @blur="hideTipParagraph('session-name-tip-paragraph')">
          <info-circle></info-circle>
          <p :style="tipParagraphStyle" id="session-name-tip-paragraph">{{ tr('dashboard-tip-session-name') }}</p>
        </span></th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" :style="[tableStyle, fontStyle]">{{
          tr('Session Link')
      }}<span class="dashboard-tip" tabindex="0"
          @focus="showTipParagraph('session-link-tip-paragraph')"
          @blur="hideTipParagraph('session-link-tip-paragraph')">
          <info-circle></info-circle>
          <p :style="tipParagraphStyle" id="session-link-tip-paragraph">{{ tr('dashboard-tip-session-link') }}</p>
        </span></th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" :style="[tableStyle, fontStyle]">{{
          tr('Number of Tools')
      }}</th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" :style="[tableStyle, fontStyle]">{{
          tr('Locally Anonymized')
      }}<span class="dashboard-tip" tabindex="0"
          @focus="showTipParagraph('locally-anonymized-tip-paragraph')"
          @blur="hideTipParagraph('locally-anonymized-tip-paragraph')">
          <info-circle></info-circle>
          <p :style="tipParagraphStyle" id="locally-anonymized-tip-paragraph">{{ tr('dashboard-tip-locally-anonymized')
          }}</p>
        </span></th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" :style="[tableStyle, fontStyle]">{{
          tr('Copy Session Link')
      }}<span class="dashboard-tip dashboard-tip-left" tabindex="0"
          @focus="showTipParagraph('copy-session-link-tip-paragraph')"
          @blur="hideTipParagraph('copy-session-link-tip-paragraph')">
          <info-circle></info-circle>
          <p :style="tipParagraphStyle" id="copy-session-link-tip-paragraph">{{ tr('dashboard-copy-tip-session-link') }}
          </p>
        </span></th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" v-if="featureToggles.enableSessionSharing"
        :style="[tableStyle, fontStyle]">{{ tr('Collaborators') }}<span class="dashboard-tip dashboard-tip-left"
          tabindex="0" @focus="showTipParagraph('collaborators-tip-paragraph')"
          @blur="hideTipParagraph('collaborators-tip-paragraph')">
          <info-circle></info-circle>
          <p :style="tipParagraphStyle" id="collaborators-tip-paragraph">{{ tr('dashboard-tip-collaborators') }}</p>
        </span></th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col"
        v-if="featureToggles.enableSessionSharing && featureToggles.enableSessionReadOnlySharing"
        :style="[tableStyle, fontStyle]">{{ tr('Viewers (read-only)') }}<span class="dashboard-tip dashboard-tip-left"
          tabindex="0" @focus="showTipParagraph('viewers-tip-paragraph')"
          @blur="hideTipParagraph('viewers-tip-paragraph')">
          <info-circle></info-circle>
          <p :style="tipParagraphStyle" id="viewers-tip-paragraph">{{ tr('dashboard-tip-viewers') }}</p>
        </span></th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" :style="[tableStyle, fontStyle]">{{
          tr('Last accessed')
      }}</th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" :style="[tableStyle, fontStyle]">{{
          tr('Duplicate')
      }}<span class="dashboard-tip dashboard-tip-left" tabindex="0"
          @focus="showTipParagraph('duplicate-session-tip-paragraph')"
          @blur="hideTipParagraph('duplicate-session-tip-paragraph')">
          <info-circle></info-circle>
          <p :style="tipParagraphStyle" id="duplicate-session-tip-paragraph">{{ tr('dashboard-tip-duplicate-session') }}
          </p>
        </span></th>
      <th class="mdc-data-table__header-cell" role="columnheader" scope="col" :style="[tableStyle, fontStyle]">{{
          tr('Delete')
      }}<span class="dashboard-tip dashboard-tip-left" tabindex="0"
          @focus="showTipParagraph('delete-session-tip-paragraph')"
          @blur="hideTipParagraph('delete-session-tip-paragraph')">
          <info-circle></info-circle>
          <p :style="tipParagraphStyle" id="delete-session-tip-paragraph">{{ tr('dashboard-tip-delete-session') }}</p>
        </span></th>
    </tr>
  </thead>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { isThemeLight, tipParagraphStyleFunc } from '@/common/shared.js'
import InfoCircle from '@/components/ui/icons/InfoCircle.vue'

import { tableStyle } from './tables.js'

export default {
  components: {
    InfoCircle,
  },
  setup() {
    const featureToggles = computed(() => store.getters.featureToggles)
    const store = useStore()
    const tr = inject('tr')

    const checkboxColor = computed(() => store.getters.checkboxColor)

    const checkboxOutline = computed(() => {
      return {
        outlineColor: isThemeLight[store.getters.currentThemeName] ? '#000' : '#fff',
      }
    })

    const tipParagraphStyle = computed(() => tipParagraphStyleFunc(store))

    const showTipParagraph = (elId) => document.getElementById(elId).style.display = 'block'
    const hideTipParagraph = (elId) => document.getElementById(elId).style.display = null

    const fontStyle = computed(() => {
      return {
        fontFamily: store.getters.font,
      }
    })

    return {
      checkboxColor,
      checkboxOutline,
      featureToggles,
      fontStyle,
      hideTipParagraph,
      showTipParagraph,
      tableStyle,
      tipParagraphStyle,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './tables.scss';
</style>
